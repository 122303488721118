export const INFLUENCERS_CATEGORIES = [
  { name: 'Gaming video creator' },
  { name: 'Digital creator' },
  { name: 'Sportsperson' },
  { name: 'Artist' },
  { name: 'Video creator' },
  { name: 'Actor' },
  { name: 'Sports team' },
  { name: 'Personal blog' },
  { name: 'Public figure' },
  { name: 'Doctor' },
  { name: 'Blogger' },
  { name: 'Musician' },
  { name: 'Brand' },
  { name: 'Circus' },
  { name: 'DJ' },
  { name: 'Fitness model' },
  { name: 'Musician/Band' },
  { name: 'People' },
  { name: 'Chef' },
  { name: 'News & media website' },
  { name: 'Health/Beauty' },
  { name: 'Supermarket/Convenience store' },
  { name: 'Product/Service' },
  { name: 'Media' },
  { name: 'Fashion model' },
  { name: 'Motorcycle manufacturer' },
  { name: 'Sports' },
  { name: 'Aerospace company' },
  { name: 'Arts & entertainment' },
  { name: 'Government organisation' },
  { name: 'Media/news company' },
  { name: 'TV channel' },
  { name: 'Community' },
  { name: 'Entrepreneur' },
  { name: 'Film' },
  { name: 'Shopping & retail' },
  { name: 'Entertainment website' },
  { name: 'Art' },
  { name: 'Florist' },
  { name: 'Home decor' },
  { name: 'Fan Page' },
  { name: 'Publisher' },
  { name: 'Food & drink company' },
  { name: 'Author' },
  { name: 'TV Programme' },
  { name: 'Society & culture website' },
  { name: 'Business service' },
  { name: 'Travel company' },
  { name: 'Local & travel website' },
  { name: 'Editor' },
  { name: 'Magazine' },
  { name: 'E-sports team' },
  { name: 'Writer' },
  { name: 'Comedian' },
  { name: 'Motivational speaker' },
  { name: 'Video game' },
  { name: 'Just for fun' },
  { name: 'Clothing (brand)' },
  { name: 'Cars' },
  { name: 'Waste management company' },
  { name: 'Fitness trainer' },
  { name: 'Gamer' },
  { name: 'Professional sports team' },
  { name: 'Metal fabricator' },
  { name: 'Camera/Photo' },
  { name: 'Record label' },
  { name: 'Film/Television studio' },
  { name: 'Home & garden website' },
  { name: 'Beauty salon' },
  { name: 'Music chart' },
  { name: "Teen & children's website" },
  { name: 'Coach' },
  { name: 'Food & drink' },
  { name: 'Bar' },
  { name: 'News personality' },
  { name: 'Sports league' },
];

export const INFLUENCERS_FOLLOWERS_RANGE = [
  { name: '10K', value: 10000 },
  { name: '15K', value: 15000 },
  { name: '20K', value: 25000 },
  { name: '25K', value: 20000 },
  { name: '30K', value: 30000 },
  { name: '35K', value: 35000 },
  { name: '40K', value: 40000 },
  { name: '50K', value: 50000 },
  { name: '100K', value: 100000 },
  { name: '200K', value: 200000 },
  { name: '500K', value: 500000 },
  { name: '1M', value: 1000000 },
  { name: '2M', value: 2000000 },
  { name: '3M', value: 3000000 },
  { name: '4M', value: 4000000 },
  { name: '5M', value: 5000000 },
  { name: '10M', value: 10000000 },
  { name: '20M', value: 20000000 },
  { name: '30M', value: 30000000 },
  { name: '40M', value: 40000000 },
  { name: '50M', value: 50000000 },
  { name: '50M+', value: 50000001 },
];
