import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { UserService } from '../../@core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
})
export class LayoutComponent implements OnInit {
  isSidebar = true;
  currentUser = null;
  url = null;

 

  constructor(private userService: UserService, private router: Router, private modalService: NgbModal, config: NgbModalConfig) {
    this.userService.currentUser.subscribe((u) => (this.currentUser = u));
    router.events.subscribe((val) => {
      // @ts-ignore
      this.url = val.url;
    });
  }
  dd() {
    console.log(this.userService.currentUser);

    // @ts-ignore
    return JSON.stringify(this.userService.currentUser.isActiveSubscription);
  }
  ngOnInit() {
    console.log(this.router);
    
    this.url = this.router.url;
  }


}
