<div class="card border-0">
  <div class="card-body">
  <div class="row">
    <div class="col-md-12 mb-3">
      <div class="d-flex">
        <div class="heading">
          <h4>Headeing here</h4>
        </div>
        <div class="ml-auto">
          <a href="javascript:void(0)" class="btn btn-primary" routerLink='/add-new'>Add New</a>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <table class="table">
        <thead class="thead-light">
          <tr>
            <th scope="col">No.</th>
            <th scope="col">Name</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">1</th>
            <td>Mark</td>
            <td>Otto</td>
            <td>
              <i class="fas fa-pencil-alt text-info mr-4 c-p"></i>
              <i class="fas fa-trash text-danger c-p"></i>
            </td>
          </tr>
          <tr>
            <th scope="row">2</th>
            <td>Jacob</td>
            <td>Thornton</td>
            <td>
              <i class="fas fa-pencil-alt text-info mr-4 c-p"></i>
              <i class="fas fa-trash text-danger c-p"></i>
            </td>
          </tr>
          <tr>
            <th scope="row">3</th>
            <td>Larry</td>
            <td>the Bird</td>
            <td>
              <i class="fas fa-pencil-alt text-info mr-4 c-p"></i>
              <i class="fas fa-trash text-danger c-p"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  </div>
</div>
