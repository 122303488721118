<!-- Page content -->

<div class="page-content">

  <app-sidebar></app-sidebar>
  <!-- Main content -->
  <div class="content-wrapper">
    <app-header></app-header>
    <!-- Content area -->
    <div class="content">
      <div class="main-div">
       
        <div *ngIf="
            currentUser?.isActiveSubscription || url == '/subscriptions' || currentUser?.role!=3  ;
            else other_content
          ">
          <router-outlet></router-outlet>
        </div>
        <ng-template #other_content>
          <div #other_content class="d-flex flex-column justify-content-center align-items-center"
            style="min-height: 400px">
            <p>
              It seems you don't have a subscription OR your subscription has
              expired
            </p>
            <p>Please Purchase a subscription first</p>
            <button routerLink="/subscriptions" class="btn btn-primary">
              View All subscriptions
            </button>
          </div>
        </ng-template>
      </div>
    </div>
    <!-- /content area -->
  </div>
  <!-- /main content -->
</div>
<!-- /page content -->