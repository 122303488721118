<!-- Main navbar -->
<div class="navbar navbar-expand-md navbar-light">
  <div
    class="collapse navbar-collapse"
    [ngbCollapse]="isTreeCollapsed"
    id="navbar-mobile"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <a
          href="javascript:void(0)"
          class="bg-transparent sidebar-mobile-main-toggle border-0"
          (click)="sidebarLgCollapse()"
          type="button"
        >
          <i class="fas fa-bars text-dark"></i>
        </a>
      </li>
    </ul>
    <div class="header-bar shadow1">
      <button
        class="bg-transparent border-0 sidebar-control sidebar-main-toggle"
        (click)="goBack()"
      >
        <img src="assets/sidebar-close.png" height="40" />
      </button>
      <!-- <h6 class="fw-600 text-aqua-green pl-lg-2 mb-0">View Compaigns</h6> -->
      <ul class="navbar-nav ml-auto align-items-center">
       
        <li class="nav-item" *ngIf="currentUser?.role == 3">
          <button
            type="button"
            class="btn btn-light"
            routerLink="/subscriptions"
          >
            Manage Subscriptions
          </button>

          <!-- <button type="button" class="btn btn-primary">Primary</button> -->
        </li>
        <li class="nav-item">
          <a href="javascript:void(0)" class="nav-link">
            <img src="assets/help.png" class="pr-lg-3" />
          </a>
        </li>
        <li class="nav-item" ngbDropdown #myDrop="ngbDropdown">
          <a
            href="javascript:void(0)"
            class="nav-link"
            id="dropdownManual"
            ngbDropdownAnchor
            (focus)="myDrop.open()"
          >
            <div
              ngbDropdownMenu
              aria-labelledby="dropdownManual"
              [ngClass]="{ notificationsList: true, show: newNotifications }"
            >
              <!-- <sup *ngIf="
                  notifications &&
                  notifications.totalDocs &&
                  notifications.totalDocs !== 0
                " class="unreadCount">{{ newNotificationsCount }}</sup> -->
              <span *ngIf="newNotifications"
                >{{ notificationsCount }} new notifications</span
              >
              <span *ngIf="!newNotifications"
                >{{ allNotificationsCount }} notifications</span
              >

              <div class="d-flex align-center notificationsContainer">
                <!-- <div class="user mr-2">
                    <i class="fas fa-user"></i>
                  </div> -->
                <ul
                  *ngIf="newNotifications"
                  class="text-left"
                  style="width: 100%"
                >
                  <li
                    class="notificationItem"
                    *ngFor="let notification of this.notifications"
                    style="background-color: rgb(236, 236, 236)"
                  >
                    {{ notification.title }}
                  </li>
                </ul>
                <ul
                  *ngIf="!newNotifications"
                  class="text-left"
                  style="width: 100%"
                >
                  <li
                    class="notificationItem"
                    *ngFor="let notification of this.allNotifications"
                    style="background-color: rgb(236, 236, 236)"
                  >
                    {{ notification.title }}
                  </li>
                </ul>
              </div>
              <div
                *ngIf="allNotifications.length > 0"
                style="
                  border: 1px solid;
                  padding: 2px;
                  box-sizing: border-box;
                  margin: 2px;
                "
                (click)="clearNotificatons()"
              >
                Clear All
              </div>
            </div>

            <div class="mr-lg-3 px-2 py-1 notification">
              <div class="d-flex align-items-center">
                <img src="assets/notification.png" />
                <span
                  style="
                    background: red;
                    text-align: center;
                    box-sizing: border-box;
                    padding: 3px;
                    border-radius: 100%;
                    font-size: 10px;
                    width: fit-content;
                    height: 18px;
                    padding-top: 2px;
                    padding-right: 4px;
                    position: absolute;
                    top: 6%;
                    right: 35%;
                  "
                  class="text-white small"
                  >{{
                    newNotifications
                      ? notificationsCount
                      : allNotificationsCount
                  }}</span
                >
              </div>
            </div>
          </a>
        </li>
        <li
          *ngIf="currentUser"
          ngbDropdown
          class="nav-item dropdown dropdown-user"
        >
          <a
            href="javascript:void(0)"
            ngbDropdownToggle
            id="dropdownBasic1"
            class="navbar-nav-link d-flex align-items-center"
          >
            <div class="profile-img">
              <img [src]="currentUser.image | image" />
            </div>
            <span class="text-white font">{{ currentUser | name }}</span>
            <span><i class="fas fa-angle-down pl-2 pt-2 text-aqua"></i></span>
          </a>

          <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
            <a
              href="javascript:void(0)"
              ngbDropdownItem
              class="dropdown-item"
              routerLink="/profile"
            >
              My Profile
            </a>
            <a
              href="javascript:void(0)"
              ngbDropdownItem
              *ngxPermissionsOnly="[UserType.brand]"
              class="dropdown-item"
              [routerLink]="['/payments/brand/', this.currentUser?._id]"
            >
              Transactions History
            </a>
            <a
              href="javascript:void(0)"
              ngbDropdownItem
              class="dropdown-item"
              routerLink="/profile/accounts"
            >
              Social Accounts
            </a>
            <a
              href="javascript:void(0)"
              ngbDropdownItem
              class="dropdown-item"
              [routerLink]="['/auth/instagram', this.currentUser?._id]"
              routerLinkActive="router-link-active"
            >
              Instagram
            </a>
            <a
              href="https://influocial.freshdesk.com/support/home"
              ngbDropdownItem
              class="dropdown-item"
            >
              FAQs
            </a>
            <a
              href="javascript:void(0)"
              ngbDropdownItem
              class="dropdown-item"
              routerLink=""
            >
              Terms & Conditions
            </a>
            <a
              href="javascript:void(0)"
              ngbDropdownItem
              class="dropdown-item"
              routerLink="/list"
            >
              Team
            </a>
            <a
              href="javascript:void(0)"
              ngbDropdownItem
              class="dropdown-item text-danger"
              (click)="onLogoutClick()"
            >
              Logout
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <!-- <span class="box"><img src="../../../assets/Vector.png"></span> -->
  <div class="d-md-none">
    <ul class="d-flex align-items-center">
      <li
        *ngIf="currentUser"
        ngbDropdown
        class="nav-item dropdown dropdown-user"
      >
        <a
          href="javascript:void(0)"
          ngbDropdownToggle
          id="dropdownBasic1"
          class="navbar-nav-link d-flex align-items-center"
        >
          <div class="profile-img">
            <img [src]="currentUser.image | image" />
          </div>
          <!-- <span class="text-white">Pixelz Warrios</span> -->
          <span><i class="fas fa-angle-down pl-2 pt-2 text-aqua"></i></span>
        </a>

        <div class="dropdown-menu pull-right" ngbDropdownMenu>
          <a
            href="javascript:void(0)"
            ngbDropdownItem
            class="dropdown-item"
            routerLink="/profile"
          >
            My profile
          </a>
          <a
            href="javascript:void(0)"
            ngbDropdownItem
            *ngxPermissionsOnly="[UserType.brand]"
            class="dropdown-item"
            [routerLink]="['/payments/brand/', this.currentUser?._id]"
          >
            Transactions History
          </a>
          <a
            href="javascript:void(0)"
            ngbDropdownItem
            class="dropdown-item"
            routerLink="/account"
          >
            Social Accounts
          </a>
          <a
            href="javascript:void(0)"
            ngbDropdownItem
            class="dropdown-item"
            [routerLink]="['/auth/instagram', this.currentUser?._id]"
            routerLinkActive="router-link-active"
          >
            Instagram
          </a>
          <a
            href="https://influocial.freshdesk.com/support/home"
            target="_blank"
            ngbDropdownItem
            class="dropdown-item"
          >
            FAQs
          </a>
          <a
            href="javascript:void(0)"
            ngbDropdownItem
            class="dropdown-item"
            routerLink="/account"
          >
            Terms & Conditions
          </a>
          <a
            href="javascript:void(0)"
            ngbDropdownItem
            class="dropdown-item"
            routerLink="/account"
          >
            Team
          </a>
          <a
            href="javascript:void(0)"
            ngbDropdownItem
            class="dropdown-item text-danger"
            (click)="onLogoutClick()"
          >
            Logout
          </a>
        </div>
      </li>
      <li class="nav-item" style="position: absolute; right: 0; top: 10px">
        <a href="javascript:void(0)" class="nav-link">
          <button
            class="navbar-toggler sidebar-mobile-main-toggle border-0"
            (click)="sidebarSmCollapse()"
            type="button"
          >
            <i class="fas fa-bars text-dark"></i>
          </button>
        </a>
      </li>
    </ul>
  </div>
</div>
<!-- /main navbar -->
