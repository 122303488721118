<!-- carousal  -->
<ngb-carousel *ngIf="images" style="background-color: black;width: 45%;margin-left:25%;margin-bottom:20px;">
  <ng-template *ngFor="let img of images" ngbSlide>
    <div class="picsum-img-wrapper">
      <img [src]="img" class="w-100" />
    </div>
    <!-- <div class="carousel-caption">
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </div> -->
  </ng-template>
</ngb-carousel>