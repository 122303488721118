export const SOCIAL_MEDIA_CHANNELS = [
  {
    id: 1,
    name: 'Instagram',
    logoUrl: 'assets/insta.png',
    hasPost: true,
    hasStory: true,
  },
  {
    id: 2,
    name: 'TikTok',
    logoUrl: 'https://1000logos.net/wp-content/uploads/2019/06/Tiktok_Logo.png',
    hasPost: true,
    hasStory: true,
  },
  // {
  //   id: 2,
  //   name: 'Facebook',
  //   logoUrl: 'assets/fb.png',
  //   hasPost: true,
  //   hasStory: true,
  // },
  // {
  //   id: 3,
  //   name: 'Tiktok',
  //   logoUrl: 'assets/tik.png',
  //   hasPost: true,
  //   hasStory: false,
  // },
  // {
  //   id: 4,
  //   name: 'Youtube',
  //   logoUrl: 'assets/youtube.png',
  //   hasPost: true,
  //   hasStory: true,
  // },
];
