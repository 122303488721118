<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <h6 class="fs-14 fw-400 text-muted my-3">Add New User</h6>
    </div>
    <div class="col-md-4">
      <div class="profile-img mb-3">
        <img src="assets/profile1.png" alt="">
      </div>
      <div class="text-center">
        <label for="input" class="">Address</label>
      </div>
      <div class="form-group">
        <label for="input" class="">Street</label>
        <input type="text" class="form-control" >
      </div>
      <div class="form-group">
        <label for="input" class="">City</label>
        <input type="text" class="form-control" >
      </div>
      <div class="form-group">
        <label for="input" class="">State, Region or Province</label>
        <input type="text" class="form-control" >
      </div>
      <div class="form-group">
        <label for="input" class="">Postcode</label>
        <input type="text" class="form-control" >
      </div>
    </div>
    <div class="col-md-1"></div>
    <div class="col-md-4">
      <h2 class="fw-500 my-3">Jane Doe</h2>
      <div class="form-group">
        <label for="input" class="">Contact</label>
        <input type="text" class="form-control" >
      </div>
      <div class="form-group">
        <label for="input" class="">Email Address</label>
        <input type="text" class="form-control" >
      </div>
      <div class="form-group">
        <label for="input" class="">Gender</label>
        <input type="text" class="form-control" >
      </div>
      <div class="form-group">
        <label for="input" class="">Date of Birth</label>
        <input type="text" class="form-control" >
      </div>
      <div class="form-group">
        <label for="input" class="">Home Country</label>
        <input type="text" class="form-control" >
      </div>
    </div>
  </div>
</div>
