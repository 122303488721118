import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CampaignService, UserService } from 'src/app/@core';
import { UserType } from 'src/app/@models/user/user-type';
import { TableFiltersComponent } from '../table-filters/table-filters.component';
import { currencyExchangeService } from 'src/app/@core/services/currency-exchange.service';

@Component({
  selector: 'campaign-list-table',
  templateUrl: './list-table.component.html',
  styleUrls: ['./list-table.component.css'],
})
export class ListTableComponent implements OnInit {
  @Input() result;
  @Input() isMeasureList: boolean = false;
  @Input() isCreatedByVisible: boolean = false;
  @Output() onCampaignTypeChanged = new EventEmitter();
  @Output() onFilterChange = new EventEmitter();


  UserType = UserType;
  is_UK_user = false;
  currencyRate:any = 1;

  constructor(
    private router: Router,
    private campaignService: CampaignService,
    private userService: UserService,
    private currencyService: currencyExchangeService

  ) { }

  ngOnInit() {
    console.log(this.result);
    if (this.userService.getCurrentUser().country_code == "UK") {
      this.is_UK_user = true;
      this.currencyService.getConvertedTo().subscribe(res => {
        this.setExchangeRate(res.data.GBP);
      })
    }
  }

  setExchangeRate(rate) {
    console.log("curre rateL:", rate);
    this.currencyRate = parseFloat(rate).toFixed(2);
  }

  addDays(date, days) {
    var result = new Date(date);
    var endDate = result.getDate() + days;
    result.setDate(endDate);
    return result;
  }

  isActive(date, days) {
    var endDate = new Date(date);
    endDate.setDate(endDate.getDate() + days);
    var today = new Date();
    return endDate >= today;
  }

  detail(id: any) {
    this.router.navigate(['/campaigns/', id]);
  }

  edit(id: any) {
    let brandId = localStorage.getItem("brandIdCampaign");
    this.router.navigate(['/brands/', brandId, 'campaigns', id, 'edit']);
  }

  viewProposals(id: any) {
    this.isMeasureList
      ? this.router.navigate(['measure/posts/', id])
      : this.router.navigate(['proposal/campaign-proposals/', id]);
  }

  pauseCampaign(id: any, campaign: any) {
    // changing status to inactive
    campaign["status"] = 3;


    this.campaignService.updateCampaign(id, campaign).subscribe((res) => {

      if (res.status == 200) {
        window.location.reload();
      }

    })

    this.onCampaignTypeChanged.emit(3);
    this.onFilterChange.emit(3);
  }

  resumeCampaign(id: any, campaign: any) {
    // changing status to active
    campaign["status"] = 2;
    // console.log("Modified Campaign::", " ", campaign);

    this.campaignService.updateCampaign(id, campaign).subscribe((res) => {
      if (res.status == 200) {
        window.location.reload();
      }
    })

    this.onCampaignTypeChanged.emit(2);
    this.onFilterChange.emit(2);
  }
  endCampaign(id: any, campaign: any) {
    // changing status to Complete
    campaign["status"] = 5;
    // console.log("Modified Campaign::", " ", campaign);

    this.campaignService.updateCampaign(id, campaign).subscribe((res) => {
      if (res.status == 200) {
        window.location.reload();
      }
    })

    this.onCampaignTypeChanged.emit(5);
    this.onFilterChange.emit(5);
  }

  hasCampaignStarted = (date) => {
    var startDate = new Date(date).getTime();
    var today = new Date().getTime();
    return today >= startDate;
  }
}
