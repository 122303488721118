import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationService, UserService } from '../../services';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { UserType } from 'src/app/@models';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public isTreeCollapsed = true;
  UserType = UserType;
  userHeaderService: Subscription = new Subscription();
  currentUser = null;
  notifications: any = [];
  notificationsCount: any;
  newNotifications: any = false;
  allNotifications: any[] = [];
  allNotificationsCount: any = 0;
  constructor(
    private notificationService: NotificationService,
    private userService: UserService,
    private router: Router,
    private _location: Location
  ) {
    this.userService.currentUser.subscribe((u) => (this.currentUser = u));
  }

  ngOnInit(): void {
    this.userService.currentUser.subscribe((user) => {
      console.log(user);
      if (user) {
        this.getNotifications();
        this.notificationService
          .getEvent(user._id)
          .subscribe((res) => this.getNotifications());
      }
      if (user.instagram) {
        console.log('instagram id-' + user.instagram.profileInfo.username);
        this.userService
          .getInstaProfilePicFromUsername(user.instagram.profileInfo.username)
          .subscribe((res) => {
            console.log('insta res::', res);
          });
      }
    });
  }

  sidebarLgCollapse() {
    const element = document.getElementsByTagName('body')[0];
    if (element.classList.contains('sidebar-xs')) {
      element.classList.remove('sidebar-xs');
    } else {
      element.classList.add('sidebar-xs');
    }
  }

  clearNotificatons() {
    this.notificationService.clearAll().subscribe((response) => {
      if (response) {
        console.log(response);
        this.notifications = [];
        this.allNotifications = [];
        this.notificationsCount = 0;
        this.allNotificationsCount = 0;
      }
    });
  }

  getNotifications() {
    this.notificationService.getNotification().subscribe((response) => {
      this.notifications = response.data.result.docs;
      if (this.notifications instanceof Array) {
        this.allNotifications = this.notifications;
        this.allNotificationsCount = this.allNotifications.length;
        this.notifications = this.notifications.filter(
          (el) => el.isRead == false
        );
        this.notificationsCount = this.notifications.length;
        if (this.notificationsCount > 0) {
          this.newNotifications = true;
        }
        // Mark notifs as read
        // this.newNotifications = true;
        console.log('Marking notifs');
        setTimeout(() => {
          this.notificationService.markAll().subscribe((res) => {
            console.log(res);
            this.newNotifications = false;
          });
        }, 5000);

        // console.log("Notifications count:", this.notificationsCount);
        // console.log("Notificatons new", this.notifications);
        // console.log("notificationsCount:", this.notificationsCount);
        // console.log("Is new notifs?:", this.newNotifications);
      }
    });
  }
  sidebarSmCollapse() {
    const element = document.getElementsByTagName('body')[0];
    if (element.classList.contains('sidebar-mobile-main')) {
      element.classList.remove('sidebar-mobile-main');
    } else {
      element.classList.add('sidebar-mobile-main');
    }
  }
  onLogoutClick() {
    this.userService.logout();
    this.router.navigate(['auth']);
  }

  ngOnDestroy() {
    this.userHeaderService.unsubscribe();
  }

  goBack() {
    this._location.back();
  }
  handleManageSubscriptions() {
    
  }
}
