import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { countries } from '../@constants';
import {
  INFLUENCERS_CATEGORIES,
  INFLUENCERS_FOLLOWERS_RANGE,
} from '../@constants/influencers-categories';
import { CollaborationsService } from '../@core/services/collaborations.service';
import _ from 'lodash';
import { UserService } from '../@core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-discovery',
  templateUrl: './discovery.component.html',
  styleUrls: ['./discovery.component.css'],
})
export class DiscoveryComponent implements OnInit {
  rows = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  currentUser = null;
  currentPage = 1;
  pages = [];
  limit = 50;
  filters = { engagement: 100 };
  engagements = [
    { name: 10 },
    { name: 20 },
    { name: 30 },
    { name: 40 },
    { name: 50 },
    { name: 60 },
    { name: 70 },
    { name: 80 },
    { name: 90 },
    { name: 100 },
  ];
  influencers_categories = INFLUENCERS_CATEGORIES;
  sort_types = [
    'Followers',
    'Engagement rate',
    'Est. cost',
    'Total likes',
    'Total posts',
  ];
  influencers_followers_range = INFLUENCERS_FOLLOWERS_RANGE;
  influencers_countries = countries;
  influencers = [];
  influencers_to_show = [];
  isModalOpen = false;
  search: any;
  category = '';
  sortFilter = '';
  followers: any = 0;
  isLoading: boolean = false;
  @ViewChild('content') content!: TemplateRef<any>;
 
  engagement: any = 0;
  location: any = '';
  selectedInfluencer: any;
  selectedTab: number = 0;
  excludedKeys: string[] = [
    'audienceLocation(continent)1',
    'audienceLocation(continent)2',
    'audienceLocation(continent)3',
    'audienceLocation(country)1',
    'audienceLocation(country)2',
    'audienceLocation(country)3',
    'audienceLocation(state)1',
    'audienceLocation(state)2',
    'audienceLocation(state)3',
    'audienceLocation(region)1',
    'audienceLocation(region)2',
    'audienceLocation(region)3',
    'audienceLocation(place/city)1',
    'audienceLocation(place/city)2',
    'audienceLocation(place/city)3',
    'audienceAge(13-17)',
    'audienceAge(18-24)',
    'audienceAge(25-34)',
    'audienceAge(35-44)',
    'audienceAge(45-54)',
    'audienceAge(55-64)',
    'audienceAge(65+)',
    'audienceEthnicity(caucasian)',
    'audienceEthnicity(asian)',
    'audienceEthnicity(black)',
    'audienceEthnicity(hispanic)',
    'audienceGender(male)',
    'audienceGender(female)',
  ];

  constructor(
    private collaborationsService: CollaborationsService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private userService: UserService,
    private router: Router
  ) {
    this.userService.currentUser.subscribe((u) => (this.currentUser = u));
    router.events.subscribe((val) => {
      // @ts-ignore
      this.url = val.url;
    });

  }

  ngOnInit() {
    // if (new Date().getTime() >= new Date(("HEROKUX_ALPHA-VERSIONX2023-08-10").split("X")[2]).getTime()) {
    //   window.location.reload();
    // }
    this.getInfluencers();
   
  }

  getInfluencers() {
    this.isLoading = true;
    this.collaborationsService.getInfluencers().subscribe(
      (response) => {
        console.log(response);
        if (response.status === 200) {
          if (response.data.length > 0) {
            response.data.forEach((influencer: any) => {
              influencer.profilePicture =
                environment.file_url + '/' + influencer.profilePicture;
              this.influencers.push(influencer);
            });
          };

          if (this.influencers.length % this.limit > 0) {
            this.pages = Array(Number.parseInt((this.influencers.length / this.limit).toString()) + 1).fill(1).map((x, y) => x + y);
          } else {
            this.pages = Array(Number.parseInt((this.influencers.length / this.limit).toString())).fill(1).map((x, y) => x + y);
          }

          this.setPage(1);
          this.isLoading = false;

          console.log(this.influencers);
          localStorage.setItem('influencers', JSON.stringify(this.influencers));
        }
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }


  setPage(page) {
    this.currentPage = page;
    let start = (page == 1) ? 0 : ((page - 1) * this.limit) + 1;
    this.influencers_to_show = this.influencers.slice(start, start + this.limit);

  }


  filterByEngagement(engagement: any) {
    console.log(engagement);
    if (engagement === undefined) {
      this.engagement = 0;
    } else {
      this.engagement = engagement.name;
    }
    this.filterInfluencers();
  }
  filterByFollowers(followers: any) {
    if (followers === undefined) {
      this.followers = 0;
    } else {
      this.followers = followers.value;
    }
    this.filterInfluencers();
  }
  filterByLocation(location: any) {
    if (location === undefined) {
      this.location = '';
    } else {
      this.location = location.name;
    }
    this.filterInfluencers();
  }
  filterByCategory(category: any) {
    if (category === undefined) {
      this.category = '';
    } else {
      this.category = category.name;
    }
    this.filterInfluencers();
  }
  camelCaseToWords(s: string) {
    const result = s.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }
  sortBy(sortFilter: any) {
    this.sortFilter = sortFilter;
    let _influencers = JSON.parse(localStorage.getItem('influencers'));
    // _influencers.sort(this.sortCondition);
    this.influencers = this.sortCondition(_influencers);
  }

  sortCondition(influencers: any) {
    var sortType = this.sortFilter;

    return influencers.sort((inf_1, inf_2) => {
      switch (sortType) {
        case 'Followers':
          return inf_1.follower - inf_2.follower;
        case 'Engagement rate':
          return inf_1.avgEngagement - inf_2.avgEngagement;
        case 'Total likes':
          return inf_1.totalLikes - inf_2.totalLikes;
        case 'Est. cost':
          return inf_1.cppMin - inf_2.cppMin;
        case 'Total posts':
          return inf_1.totalPosts - inf_2.totalPosts;
        default:
          return inf_1.follower - inf_2.follower;
      }
    });
  }

  getKeys(obj: any): string[] {
    const profileData = this.filterExcludedKeys(obj, this.excludedKeys);
    return Object.keys(profileData);
  }
  getAgePercentage(audienceMetrics) {
    return [
      Boolean(audienceMetrics['audienceAge(13-17)'])
        ? (parseFloat(audienceMetrics['audienceAge(13-17)']) * 100).toFixed(2)
        : 0,
      Boolean(audienceMetrics['audienceAge(18-24)'])
        ? (parseFloat(audienceMetrics['audienceAge(18-24)']) * 100).toFixed(2)
        : 0,
      Boolean(audienceMetrics['audienceAge(25-34)'])
        ? (parseFloat(audienceMetrics['audienceAge(25-34)']) * 100).toFixed(2)
        : 0,
      Boolean(audienceMetrics['audienceAge(35-44)'])
        ? (parseFloat(audienceMetrics['audienceAge(35-44)']) * 100).toFixed(2)
        : 0,
      Boolean(audienceMetrics['audienceAge(45-54)'])
        ? (parseFloat(audienceMetrics['audienceAge(45-54)']) * 100).toFixed(2)
        : 0,
      Boolean(audienceMetrics['audienceAge(55-64)'])
        ? (parseFloat(audienceMetrics['audienceAge(55-64)']) * 100).toFixed(2)
        : 0,
      Boolean(audienceMetrics['audienceAge(65+)'])
        ? (parseFloat(audienceMetrics['audienceAge(65+)']) * 100).toFixed(2)
        : 0,
    ];
  }
  getGenderPercentage(audienceMetrics) {
    return [
      Boolean(audienceMetrics['audienceGender(male)'])
        ? (parseFloat(audienceMetrics['audienceGender(male)']) * 100).toFixed(2)
        : 0,
      Boolean(audienceMetrics['audienceGender(female)'])
        ? (parseFloat(audienceMetrics['audienceGender(female)']) * 100).toFixed(
          2
        )
        : 0,
    ];
  }
  getPercentageFromFloat(value) {
    return Boolean(value) ? (parseFloat(value) * 100).toFixed(2) + '%' : '0%';
  }
  getLocationPercentage(audienceMetrics) {
    let keys = [
      'audienceLocation(continent)1',
      'audienceLocation(continent)2',
      'audienceLocation(continent)3',
      'audienceLocation(country)1',
      'audienceLocation(country)2',
      'audienceLocation(country)3',
      'audienceLocation(state)1',
      'audienceLocation(state)2',
      'audienceLocation(state)3',
      'audienceLocation(region)1',
      'audienceLocation(region)2',
      'audienceLocation(region)3',
      'audienceLocation(place/city)1',
      'audienceLocation(place/city)2',
      'audienceLocation(place/city)3',
    ];
    const processedLocationData = [];
    for (const key in audienceMetrics) {
      if (keys.includes(key) && Boolean(audienceMetrics[key])) {
        const match = audienceMetrics[key].match(/(.*?)\s\((.*?)%\)/);
        if (match) {
          processedLocationData.push({
            region: match[1],
            percentage: match[2],
          });
        }
      }
    }
    return processedLocationData;
  }
  filterExcludedKeys(data: any, excludedKeys: string[]): any {
    const filteredData: any = {};
    for (const key in data) {
      if (!excludedKeys.includes(key)) {
        filteredData[key] = data[key];
      }
    }
    return filteredData;
  }

  resetFilters() {
    this.influencers = JSON.parse(localStorage.getItem('influencers'));
  }
  filterInfluencers() {
    let influencers = [];
    let _influencers = [];
    influencers = JSON.parse(localStorage.getItem('influencers'));

    if (this.engagement != 0) {
      console.log('inside engagement');
      influencers = influencers.filter(
        (influencer: any) => influencer.reach >= this.engagement
      );
    }

    if (this.followers != 0) {
      console.log('inside follower');
      console.log(this.followers);
      influencers = influencers.filter(
        (influencer: any) => influencer.follower >= this.followers
      );
    }
    if (this.location != '') {
      console.log('inside location');
      influencers = influencers.filter(
        (influencer: any) => influencer.country == this.location
      );
    }
    if (this.category != '') {
      console.log('inside category');
      influencers = influencers.filter(
        (influencer: any) => influencer.category == this.category
      );
    }
    console.log(influencers);
    this.influencers = influencers;
  }

  onChange(e: any, type: string) {
    console.log(e);
    let influencers = localStorage.getItem('influencers') as any;
    console.log(influencers);
    if (type === 'category') {
      this.category = e.name;
      influencers = influencers.filter((influencer) => {
        return influencer.profile_data.category_name == this.category;
      });
    }
    if (type === 'followers') {
      this.followers = e.value;
      console.log(this.followers);
      influencers = influencers.filter((influencer) => {
        console.log(
          influencer.profile_data.total_follower,
          +'    ' + this.followers
        );
        return influencer.profile_data.total_followers <= this.followers;
      });
    }
    this.influencers = influencers;
  }

  onFilterChange(e: any, type: string) {
    let influencers = localStorage.getItem('influencers');
    console.log(influencers);
  }

  openModal(influencer: any) {
    this.isModalOpen = true;
    this.selectedInfluencer = influencer;
    this.modalService.open(this.content, { size: 'xl', centered: true });
  }

 

  selectTab(num: number) {
    this.selectedTab = num;
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
