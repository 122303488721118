<ng-template #content let-modal>
  <div class="card mb-0">
    <div class="card-header border-bottom">
      <h4 class="mb-2 m-0">Select Payment Method</h4>
    </div>
    <div class="card-body pt-4">
      <ngx-paypal [config]="payPalConfig"></ngx-paypal>
    </div>
  </div>
</ng-template>
<ng-template #confirmationModal>
  <div class="modal-body">
    <h3>Payment Confirmation</h3>
    <p>Your payment has been received and you will receive an e-mail of the same shortly.</p>
  </div>
</ng-template> 
