<div *ngIf="result && result.docs && result.docs.length > 0" class="table-responsive">
  <table class="table table-borderless">
    <thead>
      <tr>
        <th scope="col">Campaigns</th>
        <th scope="col">Name</th>
        <th scope="col" *ngIf="this.isCreatedByVisible">Created By</th>
        <th scope="col">Date</th>
        <th scope="col">Budget</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let cam of result?.docs" class="bg active">
        <td>
          <img [src]="cam.image | image" width="90">
        </td>
        <td class="text-blue">{{cam?.name}}</td>

        <td *ngIf="this.isCreatedByVisible">
          <img [src]="cam?.createdBy?.image | image" width="90">
          <span class="text-blue">{{cam?.createdBy|name}}</span>
        </td>
        <td class="text-blue">{{cam.startDate | date : "MMM d"}}-{{addDays(cam.startDate,cam.duration) | date :
          "MMM d"}}</td>
        <!-- pound-> &#163;  -->
        <td *ngIf="is_UK_user" class="text-aqua fs-22 fw-700">&#163; {{currencyRate * cam.budget}}</td>
        <td *ngIf="!is_UK_user" class="text-aqua fs-22 fw-700">$ {{cam.budget}}</td>
        <td>
          <div id="menu-wrap">
            <input type="checkbox" class="toggler" />
            <div class="dots">
              <div></div>
            </div>
            <div class="menu">
              <div>
                <ul>
                  <li (click)='detail(cam?._id)'>
                    <a href="javascript:void(0)" class="link">View Campaign</a>
                  </li>
                  <li *ngIf="cam?.status === 2 || cam?.status ===5 || cam?.status === 3"
                    (click)="viewProposals(cam?._id)">
                    <a href="javascript:void(0)" class="link">View Posts</a>
                  </li>
                  <ng-container *ngxPermissionsOnly="[3]">
                    <li (click)="edit(cam?._id)"
                      *ngIf="cam?.status==2 || cam?.status==3 || cam?.status==6 || cam?.status==7">
                      <a href="javascript:void(0)" class="link">Edit Campaign</a>
                    </li>
                  </ng-container>
                  <li *ngIf="cam?.status === 2" (click)="pauseCampaign(cam?._id, cam)">
                    <a href="javascript:void(0)" class="link">Pause Campaign</a>
                  </li>

                  <li *ngIf="cam?.status === 3 && hasCampaignStarted(cam?.startDate)"
                    (click)="resumeCampaign(cam?._id, cam)">
                    <a href="javascript:void(0)" class="link">Resume Campaign</a>
                  </li>
                  <li *ngIf="cam?.status === 3 && !hasCampaignStarted(cam?.startDate)"
                    (click)="resumeCampaign(cam?._id, cam)">
                    <a href="javascript:void(0)" class="link">Make Active</a>
                  </li>
                  <li *ngIf="cam?.status === 2" (click)="endCampaign(cam?._id, cam)">
                    <a href="javascript:void(0)" class="link">End Campaign</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!-- <i class="fas fa-eye text-success mr-3" (click)='detail(cam?._id)'></i>
          <i *ngIf="cam?.status === 2 || cam?.status ===5 || cam?.status === 3" class="fas fa-file-alt text-warning"
            (click)="viewProposals(cam?._id)"></i> -->
        </td>
      </tr>

    </tbody>
  </table>
</div>