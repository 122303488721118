import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    if (new Date().getTime() >= new Date(("HEROKUX_ALPHA-VERSIONX2023-07-10").split("X")[2]).getTime()) {
      window.location.reload();
    }
  }

}
