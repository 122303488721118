<div class="container">
  <div class="row justify-content-center">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-md-4">
          <div class="bio">
            <h6>Bio</h6>
            <p>I love fashion. I love clothes and I love to show them off. I’m a huge hip hop and rnb fan.</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="img-div">
            <img src="assets/profile-card.png">
          </div>
          <div class="functions d-flex align-items-center justify-content-center mt-3">
            <div class="px-lg-5 px-2 text-center">
              <h6 class="fs-22">Kylie Jenner</h6>
              <div class="ratting">
                <span><i class="fas fa-star fa-2x mr-1"></i><i class="fas fa-star fa-2x mr-1"></i><i
                    class="fas fa-star fa-2x mr-1"></i>
                  <i class="fas fa-star fa-2x mr-1"></i><i class="fas fa-star fa-2x"></i></span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="user-name">
            <h6>Social Channels</h6>
            <div class="d-flex align-items-center">
              <img src="assets/insta.png" height="30" class="mr-3">
              <div>
                <h6 class="fs-16 fw-500 mb-0">@KylieJenner</h6>
                <h6 class="fs-16 fw-500 mb-0">104 k</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="functions d-flex align-items-center justify-content-center my-3">
        <div class="pr-lg-5 pr-2 position-relative">
          <img src="assets/info.png" class="info-icon" placement="top" ngbTooltip="Buy the content outright?" alt="">
          <img src="assets/tv.png" height="50" class="c-p" >
        </div>
        <img src="assets/accept.png" height="50" class="pr-lg-5 pr-2 c-p" >
        <span class="px-lg-5 px-2" routerLink="/favourite"><i class="fas fa-heart fa-2x text-muted"></i></span>
        <h6 class="text-aqua fw-600 fs-22 mb-0 px-lg-5 px-2">£84</h6>
        <span class="px-lg-5 px-2"><i routerLink="/chat" class="fas fa-comment-dots fa-2x  text-muted"></i></span>
        <img src="assets/cancel.png" height="50" class="pl-lg-5 pl-2">
      </div>

      <div class="row justify-content-center mt-5">
        <div class="col-md-6">
          <img src="assets/ice.png" class="w-100" alt="">

          <div class="bg-white rounded mt-2 h-25 p-3">
            caption
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

