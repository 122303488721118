<div class="pr-lg-5 pr-2 position-relative">
  <img
    src="assets/info.png"
    class="info-icon"
    placement="top"
    ngbTooltip="Buy the content outright?"
    alt=""
  />
  <img src="assets/tv.png" height="50" class="c-p" (click)="outRight()" />
</div>
