import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ApiService } from './api.service';


@Injectable({
    providedIn: 'root'
})
export class currencyExchangeService {

    serviceUrl = "https://api.freecurrencyapi.com/v1/latest?apikey=fca_live_gEuXEVZa0lMcMigKxP8Aa48cIq5dKyC7sDwUy4zD&currencies=GBP";

    constructor(private apiService: ApiService) { }

    getConvertedTo(currencyToConvertTo: any="GBP"): Observable<any> {
        return this.apiService.customGet(this.serviceUrl);
    }

}