export const COMPANY_SIZE = [
    { id: 1, name: '0-10 Employees' },
    { id: 2, name: '11-30 Employees' },
    { id: 3, name: '31-100 Employees' },
    { id: 4, name: '101-200 Employees' },
    { id: 5, name: '201-500 Employees' },
    { id: 6, name: '501-1000 Employees' },
    { id: 7, name: '1000+ Employees' },


];