export const CAMPAIGN_CATEGORIES = [
  // {id: 0, name: 'Product'},
  // {id: 1, name: 'Corporate'},
  // {id: 2, name: 'Service'},
  // {id: 3, name: 'Personal'},
  // {id: 4, name: 'Geographic'},
  // {id: 5, name: 'Cultural'},
  // {id: 6, name: 'Retail'},

  { id: 1, name: 'Animal' },
  { id: 2, name: 'Automotive' },
  { id: 3, name: 'Beauty' },
  { id: 4, name: 'Business' },
  { id: 5, name: 'Cosmetics' },
  { id: 6, name: 'Entertainment' },
  { id: 7, name: 'Fashion' },
  { id: 8, name: 'Food & Drink' },
  { id: 9, name: 'Health & Fitness' },
  { id: 10, name: 'Home Decor' },
  { id: 11, name: 'Hospitality' },
  { id: 12, name: 'Photography & Art' },
  { id: 13, name: 'Retail' },
  { id: 14, name: 'Restaurant' },
  { id: 15, name: 'Social Media' },
  { id: 16, name: 'Technology' },
  { id: 17, name: 'Travel' },
  { id: 18, name: 'Website ' },
];
