import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-view',
  templateUrl: './content-view.component.html',
  styleUrls: ['./content-view.component.css']
})
export class ContentViewComponent implements OnInit {

  @Input() result: any;
  isVideo = false;
  video_url = "";
  contentPost = [];
  constructor() {
    console.log(this.result);


  }

  ngOnInit() {
    let contentPost = this.contentPost;
    contentPost = this.result?.contentPost;
    this.contentPost = contentPost;
    // console.log("contentPost::", contentPost);
    if (contentPost.length == 1) {
      contentPost = contentPost[0];
      this.videoPost(contentPost);

    } else {
      contentPost.map(p => {

        if (this.videoPost(p)) {
          // this.isVideo = true;
          this.video_url = p;
        }
      })
      contentPost = contentPost.filter(p => !this.videoPost(p));
      this.contentPost = contentPost;
    }
  }

  videoPost(media_url) {
    media_url = media_url.split(".");
    let extension = media_url[media_url.length - 1];

    if (extension == "mp4" || extension == "mov" || extension == "avi") {
      this.isVideo = true;
      return true;
    }

    return false;
  }

}
