<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="card border-0 mb-3">
        <div class="card-body" (click)="navigateToLink(1)">
          <div class="row align-items-center">
            <div class="col-md-8 text-center">
              <h4>Influencer Campaigns</h4>
              <!-- <p class="w-50 mx-auto my-5">Retarget Collabs lets you view all the influencers you collaborated with in the past</p> -->
            </div>
            <div class="col-md-4">
              <img src="assets/head2.png" width="100%" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="card border-0 mb-3">
        <div class="card-body" (click)="navigateToLink(2)">
          <div class="row align-items-center">
            <div class="col-md-8 text-center">
              <h4>ShoutOut Campaigns</h4>
              <!-- <p class="w-50 mx-auto my-5 text-center">Retarget Groups allows you to create groups of your favourite influencers and invite them to private campaigns</p> -->
            </div>
            <div class="col-md-4">
              <img src="assets/head1.png" width="100%" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>