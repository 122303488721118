<br><br>
<ul class="list" id="filterList">
  <li *ngFor="let item of status">
    <a href="javascript:void(0)" (click)="onTypeClick(item.id)"
      [ngClass]="{active: type === item.id}"> {{item.name}} </a></li>
  
</ul>
<br>
<ul>
  <li class="ml-auto" *ngIf="brandID"><a href="javascript:void(0)" (click)="createCampaign()"
    class="btn btn-primary text-white">Create
    Campaign</a></li>
</ul>
<br>
<div class="d-flex mt-4 justify-content-between align-items-center">
  <div class="input-group">
    <div class="form-outline">
      <input type="search" placeholder="Search by name" (input)="isEmpty()" [(ngModel)]="namesearch"
        class="form-control" />
    </div>
    <button type="button" class="btn btn-primary" (click)="onSearchClick()">
      <i class="fas fa-search"></i>
    </button>
  </div>
 
</div>
<br>
<div style="width:100%">

  <ng-select [items]="campaignTypes" class="profile" bindLabel="name" bindValue="id" placeholder="Campaign Type"
    [clearable]=false [(ngModel)]="selectedCampaignType" (change)='campaignTypeSelected()'>
  </ng-select>
</div>