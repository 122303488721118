<div *ngIf="isSubmit && control.errors">
 <span
    class="text-danger"
    *ngIf="control.errors.selectionRequired">Selection is required</span>
  <span
    class="text-danger"
    *ngIf="control.errors.required">Field is required</span>
  <span
  class="text-danger"
  *ngIf="control.errors.minlength">
  Field must have at least {{control.errors.minlength.requiredLength}} characters
  </span>
  <span
  class="text-danger"
  *ngIf="control.errors.maxlength">
  Field must have upto {{control.errors.maxlength.requiredLength}} characters
  </span>
  <span class="text-danger" *ngIf="control.errors.pattern">
  Name must be in characters
  </span>



<!-- password validation -->
  <span
    class="text-danger"
    *ngIf="control.errors.confirmPasswordValidator">
      Password and Confirm Password didn't match.
  </span>
   <span
    class="text-danger"
    *ngIf="control.errors.hasCapitalCase">
     Password must include at least 1 upper case letter.
  </span>
    <span
    class="text-danger"
    *ngIf="control.errors.hasSmallCase">
     Password must include at least 1 lower case letter.
  </span>
   <span
    class="text-danger"
    *ngIf="control.errors.hasNumber">
           Password must include at least 1 digit.
  </span>
    <span
    class="text-danger"
    *ngIf="control.errors.invalidPassword">
      Password must consist of min. 6 characters.
  </span>
<!-- end password validation -->

  <!-- email validations -->
    <span
    class="text-danger"
    *ngIf="control.errors.email">Email must be a valid email address </span>
  <span
    class="text-danger"
    *ngIf="control.errors.emailAlreadyRegistered">
    {{control.errors.emailAlreadyRegistered}}
  </span>


  <!-- end email validations -->
<!-- //UsrenameValidator -->
  <span
  class="text-danger"
  *ngIf="control.errors.invalidusername">
   Username must have 4 Characters
</span>
<span
class="text-danger"
*ngIf="control.errors.usernameAlreadyRegistered">
{{control.errors.usernameAlreadyRegistered}}
</span>
<span
class="text-danger"
*ngIf="control.errors.validatePhoneNumber && control.errors.validatePhoneNumber.valid">
Phone must be a valid
</span>
<span
class="text-danger"
*ngIf="control.errors.phoneAlreadyRegistered">
{{control.errors.phoneAlreadyRegistered}}
</span>

</div>
