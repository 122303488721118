<div *ngIf="
   
      currentUser?.role != 3 ||
      currentUser?.permissions?.discovery == 'enabled';
    else other
  ">
  <div class="row mx-0 my-1">
    <div class="col-lg-2">
      <div class="inputSearch">
        <i class="fas fa-search"></i>
        <input type="search" placeholder="Search" [(ngModel)]="search" />
      </div>
    </div>
  </div>
  <div class="row mx-0 my-5" style="margin-top: 1rem !important; margin-bottom: 1rem !important">
    <div class="col-lg-2">
      <ng-select [items]="engagements" class="discoverySelect" bindLabel="name" bindValue="name"
        (change)="filterByEngagement($event)" placeholder="Engagement"></ng-select>
    </div>
    <div class="col-lg-2">
      <ng-select [items]="influencers_followers_range" class="discoverySelect" bindLabel="name" bindValue="name"
        placeholder="Followers" (change)="filterByFollowers($event)"></ng-select>
    </div>
    <div class="col-lg-2">
      <ng-select [items]="influencers_countries" class="discoverySelect" bindLabel="name" bindValue="name"
        (change)="filterByLocation($event)" placeholder="Location
        "></ng-select>
    </div>
    <div class="col-lg-2">
      <ng-select [items]="influencers_categories" class="discoverySelect" bindLabel="name" bindValue="name"
        placeholder="Category" (change)="filterByCategory($event)"></ng-select>
    </div>
    <div class="col-lg-2">
      <ng-select [items]="sort_types" class="discoverySelect" bindLabel="name" bindValue="name" placeholder="Sort by:"
        (change)="sortBy($event)"></ng-select>
    </div>
    <!-- <div class="col-lg-2">
    <ng-select [items]="influencers_categories" class="discoverySelect" [multiple]="false" [clearable]="false"
      [searchable]="true" placeholder="Sort By:"></ng-select>
  </div> -->
  </div>

  <div class="table-responsive">
    <div class="custom-pagination">
      <div style="background-color: transparent;">Page</div>
      <div *ngFor="let page of pages" (click)="setPage(page)" [ngClass]="{'current': page==this.currentPage}">{{ page }}</div>

      <!-- <div *ngIf="this.currentPage>1" (click)="setPage(this.currentPage-1)">Prev</div> -->
    </div>
    <table class="hide-on-mobile table table-borderless position-relative">
      <thead>
        <tr>
          <td>Influencer</td>
          <td>Followers</td>
          <td>Bio</td>
          <td>Engagement Rate</td>
          <td>Location</td>
          <td>Est. Cost Per Post</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let influencer of influencers_to_show | filter : search" (click)="openModal(influencer)">
          <td>
            <div class="d-flex justify-content-center align-items-center flex-column h-100">
              <div class="userName" *ngIf="influencer?.userName">
                <a class="text-dark" href="https://www.instagram.com/{{ influencer?.userName }}">
                  @{{ influencer?.userName }}</a>
              </div>
              <div class="userPic">
                <img [src]="influencer?.profilePicture" alt="" />
              </div>
            </div>
          </td>
          <td>
            <div class="d-flex justify-content-center align-items-center flex-column h-100">
              {{ influencer?.follower | shortNumber }}
            </div>
          </td>
          <td>
            <div class="d-flex justify-content-center align-items-center flex-column h-100">
              <div class="row mx-0">{{ influencer?.bio }}</div>
            </div>
          </td>
          <td>
            <div class="d-flex justify-content-center align-items-center flex-column h-100">
              <div class="fs-20 fw-400" *ngIf="influencer?.reach">
                {{ influencer?.reach | number : "1.2-2" }}%
              </div>
            </div>
          </td>

          <td>
            <div class="d-flex justify-content-center align-items-center flex-column h-100">
              <div class="fs-20 fw-500">{{ influencer?.country }}</div>
            </div>
          </td>
          <td>
            <div class="d-flex justify-content-center align-items-center flex-column h-100">
              <div class="text-green">
                {{ influencer?.cppMin | currency }} -
                {{ influencer?.cppMax | currency }}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="show-on-mobile table table-borderless position-relative">

      <thead>
        <tr>
          <td>Influencer</td>
          <td>Engagement Rate</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let influencer of influencers | filter : search" (click)="openModal(influencer)">
          <td>
            <div class="d-flex justify-content-start align-items-center flex-row h-100">
              <div class="userPic">
                <img [src]="influencer?.profilePicture" alt="" />
              </div>
              &nbsp;
              <div class="userName" *ngIf="influencer?.userName">
                @{{ influencer?.userName }}
              </div>
            </div>
          </td>

          <td>
            <div class="d-flex justify-content-center align-items-center flex-column h-100">
              <div class="fs-20 fw-400" *ngIf="influencer?.reach">
                {{ influencer?.reach | number : "1.2-2" }}%
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
</div>
<ng-template #other>
  <div class="d-flex flex-column justify-content-center align-items-center" style="min-height: 400px">
    <p>Sorry , this feature is not available in your purchased subscription</p>
    <p>Please upgrade to use this feature</p>
    <button routerLink="/subscriptions" class="btn btn-primary">
      Manage Subscriptions
    </button>
  </div>
</ng-template>

<app-loader *ngIf="isLoading"></app-loader>

<!-- modal -->
<ng-template #content let-c="close">
  <div id="modal1" class="modal-body">
    <span style="position: absolute; float: right; width: 100%; font-size: 24px" (click)="closeModal()">x</span>
    <div class="userPic mx-auto">
      <img [src]="selectedInfluencer?.profilePicture" alt="" />
    </div>
    <div class="text-brown text-center">
      <div class="fs-22 fw-500 my-2" *ngIf="selectedInfluencer?.userName">
        @{{ selectedInfluencer?.userName }}
      </div>
      <div class="fs-20 fw-500 w-250 mx-auto">
        {{ selectedInfluencer?.bio }}
      </div>
    </div>
    <div class="text-center">
      <i class="fa-solid fa-clipboard-list-check"></i>
      <div class="fs-26 fw-700">
        Category: <br />
        {{ selectedInfluencer?.category }}
      </div>
      <div class="fs-26 fw-700">
        Location: <br />
        {{ selectedInfluencer?.country }}
      </div>
      <div class="fs-26 fw-500 mt-3">
        Estimated Cost Per Post: <br />
        <div class="text-green">
          {{ selectedInfluencer?.cppMin | currency }} -
          {{ selectedInfluencer?.cppMax | currency }}
        </div>
      </div>
    </div>

    <hr />

    <div class="row mx-0">
      <div class="col-lg-3 mb-3">
        <div class="text-center">
          <div class="matricHeading mb-2">Followers</div>
          <div class="matricBox" placement="top" ngbTooltip="Total number of followers">
            {{ selectedInfluencer?.follower | shortNumber }}
          </div>
        </div>
      </div>
      <div class="col-lg-3 mb-3">
        <div class="text-center">
          <div class="matricHeading mb-2">Average Engagement</div>
          <div class="matricBox" placement="top"
            ngbTooltip="The average number of likes and comments this Creator gets based on their klast 12 posts"
            *ngIf="selectedInfluencer?.avgEngagement">
            {{ selectedInfluencer?.avgEngagement }}%
          </div>
        </div>
      </div>
      <div class="col-lg-3 mb-3">
        <div class="text-center">
          <div class="matricHeading mb-2">Engagement Rate</div>
          <div class="matricBox" placement="top"
            ngbTooltip="The average number of comments and likes on their last 12 posts, as a percentage of their total following"
            *ngIf="selectedInfluencer?.reach">
            {{ selectedInfluencer?.reach.toFixed(2) }}%
          </div>
        </div>
      </div>
      <div class="col-lg-3 mb-3">
        <div class="text-center">
          <div class="matricHeading mb-2">Average Impressions</div>
          <div class="matricBox" placement="top" ngbTooltip="The average number of times this Creator's posts are seen">
            $0.15
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="text-center">
          <div class="matricHeading mb-2">Posts</div>
          <div class="matricBox" placement="top" ngbTooltip="Some helpful content to be shown here ">
            {{ selectedInfluencer?.totalPosts }}
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="text-center">
          <div class="matricHeading mb-2">Avg. Likes per post</div>
          <div class="matricBox" placement="top" ngbTooltip="Some helpful content to be shown here ">
            {{ selectedInfluencer?.avgLikesPerPost | shortNumber }}
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="text-center">
          <div class="matricHeading mb-2 textNo_wrap">
            Avg. comments per post
          </div>
          <div class="matricBox" placement="top" ngbTooltip="Some helpful content to be shown here ">
            {{ selectedInfluencer?.avgCommentsPerPost | shortNumber }}
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="row px-5">
      <div class="col-md-6" *ngFor="let key of getKeys(selectedInfluencer.audienceMetrics)">
        <div class="card-body">
          <h5 class="card-title">{{ camelCaseToWords(key) }}</h5>
          <p class="card-text">
            {{ selectedInfluencer.audienceMetrics[key] }}
          </p>
        </div>
      </div>
      <div class="col-md-12">
        <hr />
      </div>
      <div class="col-md-6">
        <table class="table table-bordered" style="max-width: 400px">
          <thead class="thead-dark">
            <tr>
              <th>Global regions</th>
              <th>Percentage</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Caucasian</td>
              <td>
                {{
                getPercentageFromFloat(
                selectedInfluencer.audienceMetrics[
                "audienceEthnicity(caucasian)"
                ]
                )
                }}
              </td>
            </tr>
            <tr>
              <td>Asian</td>
              <td>
                {{
                getPercentageFromFloat(
                selectedInfluencer.audienceMetrics[
                "audienceEthnicity(asian)"
                ]
                )
                }}
              </td>
            </tr>
            <tr>
              <td>Black</td>
              <td>
                {{
                getPercentageFromFloat(
                selectedInfluencer.audienceMetrics[
                "audienceEthnicity(black)"
                ]
                )
                }}
              </td>
            </tr>
            <tr>
              <td>Hispanic</td>
              <td>
                {{
                getPercentageFromFloat(
                selectedInfluencer.audienceMetrics[
                "audienceEthnicity(hispanic)"
                ]
                )
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-6">
        <h3>Audience Age Break Down</h3>
        <app-bar-chart [labels]="[
            '13-17',
            '18-24',
            '25-34',
            '35-44',
            '45-54',
            '55-64',
            '65+'
          ]" [values]="getAgePercentage(selectedInfluencer.audienceMetrics)" [valuesLabel]="'Age'">
        </app-bar-chart>
      </div>
      <div class="col-md-12">
        <hr />
      </div>
      <div class="col-md-6">
        <h3>Gender Breakdown</h3>
        <app-pie-chart [labels]="['Male', 'Female']"
          [values]="getGenderPercentage(selectedInfluencer.audienceMetrics)"></app-pie-chart>
      </div>
      <div class="col-md-6">
        <table class="table table-bordered">
          <thead class="thead-dark">
            <tr>
              <th>Region</th>
              <th>Percentage</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="
                let location of getLocationPercentage(
                  selectedInfluencer.audienceMetrics
                )
              ">
              <td>{{ location.region }}</td>
              <td>{{ location.percentage }}%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template>

<style>
  table {
    position: relative;
  }

  .custom-pagination {
    position: fixed;
    bottom: 0;
    right:0;
    background: transparent;
    z-index: 1000000000;
    display: flex;
    width: 250px;
    justify-content: space-evenly;
    padding-right: 15px;
    padding-bottom: 15px;
    margin-right: 7.5%;
    /* font-weight: bolder; */

  }

  .custom-pagination div:hover {
    cursor: pointer;
  }

  .custom-pagination div {
    background: #fff;
    margin-right: 3px;
    border-radius: 5px;
    padding: 7px 10px;
    box-sizing: border-box;
    border: 1px solid lightgray;
  }
  .custom-pagination div.current{
    background: #bad2ff;
  }
</style>