<div class="d-flex justify-content-between p-2">

  <ngb-pagination *ngIf="totalDocs > limit" [collectionSize]="totalDocs" [pageSize]="limit" [(page)]="page"
    (pageChange)="pageChange($event)">
  </ngb-pagination>

  <select class="custom-select" style="width: auto" name="pageSize" (change)="limitChange()" [(ngModel)]="limit">
    <option [ngValue]=6>6 per page</option>
    <option [ngValue]=10>10 per page</option>
    <option [ngValue]=20>20 per page</option>
  </select>
</div>